.mySass.Counter {
  main {
    min-height: 75vh;
    display: grid;
    place-items: center;
  }
  .c-container {
    text-align: center;
  }
  #value {
    font-size: 6rem;
    font-weight: bold;
    font-family: $title2;
  }
  .btn {
    text-transform: uppercase;
    background: transparent;
    color: $black;
    padding: 0.375rem 0.75rem;
    letter-spacing: $spacing;
    display: inline-block;
    transition: $transition;
    font-size: 0.875rem;
    border: 2px solid $black;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: $radius;
    margin: 0.5rem;
  }
  .btn:hover {
    color: $white;
    background: $black;
  }
}
