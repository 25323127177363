.ToggleMode {
  height: 100%;
  font-family: "Montserrat", sans-serif;

  display: grid;
  align-items: center;
  justify-content: center;

  --tm-bg: #fcfcfc;
  --tm-bg-panel: #ebebeb;
  --tm-color-headings: #07f;
  --tm-color-text: #333;
}

.ToggleMode[data-theme="dark"] {
  --tm-bg: #333333;
  --tm-bg-panel: #434343;
  --tm-color-headings: #3694ff;
  --tm-color-text: #b5b5b5;
}

.ToggleMode {
  background-color: var(--tm-bg);

  .container {
    background-color: var(--tm-bg-panel);
    margin: 5em;
    padding: 5em;
    border-radius: 15px;

    display: grid;
    grid-template-columns: 80% auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "title switch"
      "content content";

    h1 {
      margin: 0;
      color: var(--tm-color-headings);
    }

    p {
      color: var(--tm-color-text);
      grid-area: content;
      font-size: 1.1em;
      line-height: 1.8em;
      margin-top: 2em;
    }
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 27px;
    background: grey;
    float: right;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: var(--tm-color-headings);
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 45px;
  }
}

.ToggleMode.transition,
.ToggleMode.transition *,
.ToggleMode.transition *:before,
.ToggleMode.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}
