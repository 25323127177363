.mySass.Sidebar {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;

  .sidebar-toggle {
    // position: fixed;
    // top: 2rem;
    // right: 3rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: $blue;
    transition: $transition;
    cursor: pointer;
    animation: bounce 2s ease-in-out infinite;
  }
  .sidebar-toggle:hover {
    color: $light-blue2;
  }
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }
  .close-btn {
    font-size: 1.75rem;
    background: transparent;
    border-color: transparent;
    color: $blue;
    transition: $transition;
    cursor: pointer;
    color: $red;
  }
  .close-btn:hover {
    color: $red-light;
    transform: rotate(360deg);
  }
  .logo {
    justify-self: center;
    height: 40px;
  }

  .links a {
    display: block;
    font-size: 1.5rem;
    text-transform: capitalize;
    padding: 1rem 1.5rem;
    color: $grey;
    transition: $transition;
    text-decoration: none;
  }
  .links a:hover {
    background: $light-blue2;
    color: $blue;
    padding-left: 1.75rem;
  }
  .social-icons {
    justify-self: center;
    display: flex;
    padding-bottom: 2rem;
  }
  .social-icons a {
    font-size: 1.5rem;
    margin: 0 0.5rem;
    color: $blue;
    transition: $transition;
  }
  .social-icons a:hover {
    color: $dark-blue3;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 1rem;
    box-shadow: $red;
    transition: $transition;
    transform: translate(-100%);
  }
  .show-sidebar {
    transform: translate(0);
  }
  @media screen and (min-width: 676px) {
    .sidebar {
      width: 400px;
    }
  }
}
