.aw-hero {
    width: 100%;
    height: 100vh;
    background-image: url(images/background.png);
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    font-family: sans-serif;
}

.aw-logo {
    width: 100px;
    cursor: pointer;
}

.aw-navbar {
    width: 85%;
    height: 15%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.aw {
    color: #fbfcfd;
    padding: 10px 25px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
}

.aw-content {
    color: #fbfcfd;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
    z-index: 2;
}

h1 {
    font-size: 80px;
    margin: 10px 0 30px;
    line-height: 80px;
}

.aw-side-bar {
    width: 50px;
    height: 100vh;
    background: linear-gradient(#00545d, #000729);
    position: absolute;
    right: 0;
    top: 0;
}

.aw-menu {
    display: block;
    width: 25px;
    margin: 40px auto 0;
    cursor: pointer;
}

.aw-social-links img,
.aw-useful-links img {
    width: 35px;
    margin: 5px auto;
    cursor: pointer;
}

.aw-social-links {
    width: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}

.aw-useful-links {
    width: 50px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
}
