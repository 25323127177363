.clockNeumorphism {
    width: 350px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./clock.png");
    background-size: cover;
    border: 4px solid #091921;
    border-radius: 50%;
    box-shadow: 0 -15px 15px rgba(255,255,255,0.05),
                inset 0 -15px 15px rgba(255,255,255,0.05),
                0 15px 15px rgba(0, 0, 0, 0.3),
                inset 0 15px 15px rgba(0, 0, 0, 0.3);
}

.clockNeumorphism:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
    z-index: 10000;
}

.clockNeumorphism .hour, 
.clockNeumorphism .min,
.clockNeumorphism .sec {
    position: absolute;
}

.clockNeumorphism .hour,
.clockNeumorphism .hour .hr {
    width: 160px;
    height: 160px;
}

.clockNeumorphism .min,
.clockNeumorphism .min .mn {
    width: 190px;
    height: 190px;
}

.clockNeumorphism .sec,
.clockNeumorphism .sec .sc {
    width: 230px;
    height: 230px;
}

.clockNeumorphism .hour .hr,
.clockNeumorphism .min .mn, 
.clockNeumorphism .sec .sc {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: absolute;
    border-radius: 50%;
}

.clockNeumorphism .hour .hr:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 80px;
    background: #ff105e;
    border-radius: 6px 6px 0 0;
    z-index: 10;
}

.clockNeumorphism .min .mn:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 90px;
    background: #fff;
    border-radius: 6px 6px 0 0;
    z-index: 11;
}

.clockNeumorphism .sec .sc:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 150px;
    background: #fff;
    border-radius: 6px 6px 0 0;
    z-index: 12;
}
