@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap");

.Minesweeper {
  .ms-container {
    width: 500px;
    align-content: center;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    margin: 15px;
  }

  .ms-grid {
    height: 420px;
    width: 420px;
    display: flex;
    flex-wrap: wrap;
    background-color: #dcd6bc;
    margin-top: 20px;
    border: 10px solid #dcd6bc;
    margin-bottom: 10px;
  }

  div {
    font-size: 25px;
    text-align: center;
    font-family: "Roboto Mono", monospace;
  }

  .valid {
    height: 40px;
    width: 40px;
    border: 5px solid;
    border-color: #f5f3eb #bab7a9 #bab7a9 #fff9db;
    box-sizing: border-box;
  }

  .checked {
    height: 40px;
    width: 40px;
    border: 2px solid;
    background-color: #cecab7;
    border-color: #9c998d;
    box-sizing: border-box;
  }

  .bomb {
    height: 40px;
    width: 40px;
    border: 5px solid;
    border-color: #f5f3eb #bab7a9 #bab7a9 #fff9db;
    box-sizing: border-box;
  }

  .one {
    color: #e76346;
  }

  .two {
    color: #4199d3;
  }

  .three {
    color: #57da59;
  }

  .four {
    color: #bb41d3;
  }

  #result {
    margin-top: 5px;
    color: #e76346;
  }
}
