.SocialMediaApp {
  background-color: rgb(245,245,245);
  min-height: 100vh;

  header a {
    text-decoration: none;
    color: white;
  }
}

.container {
  padding-top: 16px;
  margin: 64px auto;
  max-width: 1200px;
}