
.aw-bubbles img {
    width: 50px;
    animation: bubble 7s linear infinite;
}

.aw-bubbles {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: -70px;
}

@keyframes bubble {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: translateY(-80vh);
        opacity: 0;
    }
}

.aw-bubbles img:nth-child(1) {
    animation-delay: 2s;
    width: 25px;
}

.aw-bubbles img:nth-child(2) {
    animation-delay: 1s;
}

.aw-bubbles img:nth-child(3) {
    animation-delay: 3s;
    width: 25px;
}

.aw-bubbles img:nth-child(4) {
    animation-delay: 4.aw-5s;
}

.aw-bubbles img:nth-child(5) {
    animation-delay: 3s;
}

.aw-bubbles img:nth-child(6) {
    animation-delay: 6s;
    width: 35px;
}

.aw-bubbles img:nth-child(7) {
    animation-delay: 7s;
    width: 25px;
}