.mySass.Questions {
  .questions {
    min-height: 75vh;
    background: $background;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    margin-top: 15vh;
    margin-bottom: 4rem;
  }
  .title h2 {
    color: $gold;
    font-family: "Great Vibes", cursive;
    text-align: center;
  }
  .section-center {
    max-width: $fixed-width;
  }
  .question {
    background: $white;
    border-radius: $radius;
    box-shadow: $light-shadow;
    padding: 1.5rem 1.5rem 0 1.5rem;
    margin-bottom: 2rem;
  }
  .question-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding-bottom: 1rem;
  }

  .question-title p {
    margin-bottom: 0;
    letter-spacing: $spacing;
    color: $dark-blue3;
  }
  .question-btn {
    font-size: 1.5rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    color: $gold;
    transition: $transition;
  }
  .question-btn:hover {
    transform: rotate(90deg);
  }
  .question-text {
    padding: 1rem 0 1.5rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .question-text p {
    margin-bottom: 0;
  }
  /* hide text */
  .question-text {
    display: none;
  }
  .show-text .question-text {
    display: block;
  }
  .minus-icon {
    display: none;
  }
  .show-text .minus-icon {
    display: inline;
  }
  .show-text .plus-icon {
    display: none;
  }
}
