/*
    Template name: style.css
    description: For styling index.html file

*/


#bp-header{
    .fixed{
        width: 33vw;
        height: 120%;
        position: fixed;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url('../assets/background-img.jpg');
        background-size: cover;
        background-repeat: no-repeat;

        > img{
            width: 180px;
        }
    }
    @media (max-width: 768px) {
        .fixed{
            position: relative;
            width: 100%;
            height: 50vh;
        }
    }
}
#bp-site-main  {
   
    #info{
        background: whitesmoke;
        padding: 1rem;
    }
}

