@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  background: gray;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tweet {
  color: black;
}

.active {
  opacity: 0;
}

a {
  text-decoration: none;
}
