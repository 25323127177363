.Am__header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky;
  top: 0;
  z-index: 100;

  &__logo {
    width: 100px;
    object-fit: container;
    margin: 0 20px;
    margin-top: 18px;
  }

  &__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;

    &__input {
      height: 22px;
      padding: 10px;
      border: none;
      width: 100%;
    }

    &__icon {
      padding: 5px;
      height: 22px !important;
      background-color: #cd9042;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-evenly;

    &__option {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
      color: white;

      &__lineOne {
        font-size: 10px;
      }

      &__lineTwo {
        font-size: 10px;
        font-weight: 800;
      }
    }

    &__optionBasket {
      display: flex;
      align-items: center;
      color: white;

      &__basketCount {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
