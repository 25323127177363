.FM__message__card {
  padding: 10px;
  margin: 10px;
  width: fit-content;
  margin-right: auto;
}

.FM__message__user {
  margin-left: auto;
  margin-right: 0;
  color: white;
  text-align: left !important;
}

.FM__message__userCard {
  background-color: #0b81ff !important;
}

.FM__message__guestCard {
  background-color: #e9e9eb !important;
}
