.Am__order {
  padding: 40px;
  margin: 20px 0;
  border: 1px solid lightgray;
  background-color: white;
  position: relative;

  &__id {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  &__total {
    font-weight: 500;
    text-align: right;
  }
}
