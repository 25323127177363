.mySass.Modal {
  .hero {
    min-height: 75vh;
    background: $background;
    display: grid;
    place-items: center;
  }
  .banner {
    background: $white;
    padding: 4rem 0;
    border-radius: $radius;
    box-shadow: $light-shadow;
    text-align: center;
    width: 90vw;
    max-width: $fixed-width;
  }
  .modal-btn {
    margin-top: 2rem;
    background: $blue;
    border-color: $blue;
    color: $white;
  }
  .modal-btn:hover {
    background: transparent;
    color: $blue;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(73, 166, 233, 0.5);
    display: grid;
    place-items: center;
    transition: $transition;
    visibility: hidden;
    z-index: -10;
  }
  /* OPEN/CLOSE MODAL */
  .open-modal {
    visibility: visible;
    z-index: 10;
  }
  .modal-container {
    background: $white;
    border-radius: $radius;
    width: 90vw;
    height: 30vh;
    max-width: $fixed-width;
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
  }
  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: $red;
    cursor: pointer;
    transition: $transition;
  }
  .close-btn:hover {
    color: $red-light;
    transform: scale(1.3);
  }
}
