// Preloader
.mySass {
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    display: grid;
    justify-content: center;
    align-items: center;
    visibility: visible;
    z-index: 999;
    transition: $transition;
  }
  .hide-preloader {
    z-index: -999;
    visibility: hidden;
  }
}
