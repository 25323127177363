.ImageLightbox {
  background-color: #f1f5f8;

  img {
    max-width: 100%;
    height: auto;
  }

  .gallery {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .gallery li {
    float: left;
    width: 30%;
    margin: 1.5%;
  }

  .gallery li img {
    width: 100%;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
  }

  .gallery li img:hover {
    opacity: 0.75;
  }

  #lightbox-overlay {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(29, 31, 33, 0.95);
    transition: opacity 0.3s ease-in;
  }

  #lightbox-overlay.visible {
    opacity: 1;
    pointer-events: auto;
  }

  #lightbox-image {
    max-height: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    background: transparent url("images/loading.gif") 50% 50% no-repeat;
    text-indent: -99999px;
  }
}
