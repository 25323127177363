.FM__home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__form {
    padding: 20px;
    background-color: #e9e9eb;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;

    &__formControl {
      display: flex !important;
      flex-direction: row !important;

      &__input {
        flex: 1;
      }

      &__iconButton {
        flex: 0;
      }
    }
  }

  &__flipMove {
    width: 100%;
  }
}
