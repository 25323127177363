.RealPhone {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.RealPhone .main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #9c27b0, #8ecdff);
}

.RealPhone nav {
  width: 80%;
  position: sticky;
  margin: 20px auto;
  z-index: 1;
  display: flex;
  align-items: center;
}

.RealPhone .logo {
  flex-basis: 20%;
}

.RealPhone .logo img {
  width: 150px;
}

.RealPhone .nav-links {
  flex: 1;
  text-align: right;
}

.RealPhone .nav-links ul li {
  list-style: none;
  display: inline-block;
  margin: 0 20px;
}

.RealPhone .nav-links ul li span {
  color: #fff;
  text-decoration: none;
}

.RealPhone .information {
  width: 1000px;
  height: 1000px;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translateY(-50%);
}

.RealPhone #circle {
  width: 1000px;
  height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: rotate(0deg);
  transition: 1s;
}

.RealPhone .feature img {
  width: 70px;
}

.RealPhone .feature {
  position: absolute;
  display: flex;
  color: white;
}

.RealPhone .feature div {
  margin-left: 30px;
}

.RealPhone .feature div p {
  margin-top: 8px;
}

.RealPhone .one {
  top: 450px;
  right: 50px;
}

.RealPhone .two {
  top: 150px;
  left: 350px;
  transform: rotate(-90deg);
}

.RealPhone .three {
  bottom: 450px;
  left: 50px;
  transform: rotate(-180deg);
}

.RealPhone .four {
  bottom: 150px;
  right: 350px;
  transform: rotate(-270deg);
}

.RealPhone .mobile {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-50%);
  z-index: 1;
}

.RealPhone .controls {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.RealPhone .controls h3 {
  margin: 15px 0;
  color: #fff;
}

.RealPhone #upBtn {
  width: 15px;
  cursor: pointer;
}

.RealPhone #downBtn {
  width: 15px;
  cursor: pointer;
  transform: rotate(180deg);
}

.RealPhone .overlay {
  width: 0;
  height: 0;
  border-top: 500px solid #fff;
  border-bottom: 500px solid #fff;
  border-left: 500px solid #fff;
  border-right: 500px solid transparent;
  overflow: visible;
  position: absolute;
  left: 0;
  top: 0;
}
