.mySass.TextGenerator {
  text-align: center;
  padding: 5em 0;

  h3 {
    text-transform: uppercase;
    color: $dark-blue3;
  }
  .lorem-form {
    text-transform: capitalize;
    letter-spacing: $spacing;
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lorem-text {
    margin: 0 auto;
    width: 80%;
  }
  label {
    font-size: 1.25rem;
    color: $dark-blue3;
  }
  input {
    padding: 0.25rem 0.5rem;
    width: 4rem;
    border-radius: $radius;
    border: none;
    margin: 0 0.5rem;
    font-size: 1.25rem;
  }
  button {
    background: $light-blue;
  }
  .result {
    margin-bottom: 2rem;
  }
  .btn {
    text-transform: uppercase;
    background: $blue;
    color: $dark-blue3;
    padding: 0.375rem 0.75rem;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    display: inline-block;
    transition: transition;
    font-size: 0.875rem;
    border: 2px solid $blue;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: radius;
  }
  .btn:hover {
    color: $blue;
    background: $light-blue2;
    border-color: $light-blue2;
  }
}
