.Tabs1 {
  margin-top: 2.5em;
  font: 300 20px/1.5 "Helvetica", Arial, sans-serif;
  background-color: #f1f5f8;

  .tabs-container {
    width: 75%;
    margin: 0 auto;
  }

  /* Tab styles */
  .tabs,
  .tab-contents {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tabs {
    text-align: center;
    font-size: 0;
  }

  .tabs li {
    display: inline;
  }

  .tabs li span {
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    color: #8d8d8d;
    border: 1px solid #dcdada;
    background-color: #f5f5f5;
    height: 5em;
    line-height: 5em;
    display: inline-block;
    padding: 0 3em;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .tabs li span.active {
    background-color: #ffffff;
    color: #424242;
    cursor: default;
    opacity: 1;
  }

  .tabs li span:hover:not(.active) {
    background-color: rgba(245, 245, 245, 0.25);
    color: #424242;
  }

  .tabs li:first-child span {
    border-radius: 5px 0 0 5px;
  }

  .tabs li:last-child span {
    border-radius: 0 5px 5px 0;
  }

  .tabs li:not(:first-child) {
    margin-left: -1px;
  }

  .tab-contents {
    width: 100%;
    position: relative;
  }

  .tab-contents li {
    padding: 2em;
    display: none;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  .tab-contents li.visible {
    opacity: 1;
    pointer-events: auto;
    display: list-item;
  }
}
