.mySass.Menu {
  .menu {
    padding: 5rem 0;
  }
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .underline {
    width: 5rem;
    height: 0.25rem;
    background: $gold;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-container {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }
  .filter-btn {
    background: transparent;
    border-color: $gold;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    border-radius: $radius;
    padding: 0.375rem 0.75rem;
    color: $gold;
    cursor: pointer;
    transition: $transition;
  }
  .filter-btn:hover {
    background: $gold;
    color: $white;
  }
  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 3rem 2rem;
    justify-items: center;
  }
  .menu-item {
    display: grid;
    gap: 1rem 2rem;
    max-width: 25rem;
  }
  .photo {
    object-fit: cover;
    height: 200px;
    border: 0.25rem solid $gold;
    border-radius: $radius;
  }
  .item-info header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted $grey;
  }
  .item-info h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: $gold;
  }
  .item-text {
    margin-bottom: 0;
    padding-top: 1rem;
  }

  @media screen and (min-width: 768px) {
    .menu-item {
      grid-template-columns: 225px 1fr;
      gap: 0 1.25rem;
      max-width: 40rem;
    }
    .photo {
      height: 175px;
    }
  }
  @media screen and (min-width: 1200px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr;
    }
    .photo {
      height: 150px;
    }
  }
}
