.Am__checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  &__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
  }

  &__info {
    padding-left: 20px;

    & > button {
      background: #f0c14b;
      border: 1px solid;
      margin-top: 10px;
      border-color: #a88734 #9c7e31 #846a29;
      color: #111;
    }

    &__rating {
      display: flex;
    }

    &__title {
      font-size: 17px;
      font-weight: 800;
    }
  }
}
