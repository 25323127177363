.mySass.ColorFlipper {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 0.1rem;
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.75rem;
    font-family: "Roboto", sans-serif;
  }

  main {
    min-height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
  }

  @media screen and (min-width: 992px) {
    .section-center {
      width: 95vw;
    }
  }
  main {
    min-height: 75vh;
    display: grid;
    place-items: center;
  }

  .cf-container {
    text-align: center;
  }
  .cf-container h2 {
    background: #222;
    color: #fff;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 2.5rem;
    font-size: 2rem;
  }

  @media screen and (min-width: 800px) {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1rem;
    }
    body {
      font-size: 1rem;
    }
    h1,
    h2,
    h3,
    h4 {
      line-height: 1;
    }
  }
  .color {
    color: hsl(205, 78%, 60%);
  }

  .btn-hero {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    background: transparent;
    color: #222;
    letter-spacing: 0.1rem;
    display: inline-block;
    font-weight: 700;
    transition: all 0.3s linear;
    border: 2px solid #222;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
  }
  .btn-hero:hover {
    color: #fff;
    background: #222;
  }
}
