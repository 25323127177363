.mySass.SliderReview {
  section {
    width: 85vw;
    max-width: $fixed-width;
    margin: 0 auto;
    // margin-top: 10rem;
    text-align: center;
    padding: 1rem 0;
    /* set relative for buttons */
    position: relative;
  }
  .reviews {
    margin-bottom: 4rem;
  }
  .reviews h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reviews span {
    font-size: 0.85em;
    color: $blue;
    margin-right: 1rem;
  }
  .img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 1px solid $light-gray2;
    background: $white;
    padding: 0.25rem;
    border-radius: 50%;
    box-shadow: $dark-shadow;
    margin: 0 auto;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 500;
    color: $blue;
    letter-spacing: $spacing;
    margin: 0.5rem 0;
  }
  .title {
    margin-bottom: 1.25rem;
    letter-spacing: 2px;
    text-transform: capitalize;
  }
  .text {
    color: $grey;
    line-height: 1.8;
    max-width: 35em;
    margin: 0 auto;
    letter-spacing: 1px;
  }

  .icon:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
  .quote-icon {
    font-size: 3rem;
    margin-top: 2rem;
    color: $blue;
  }
  /* set buttons */
  .btn {
    position: absolute;
    top: 175px;
    background: $grey;
    color: $white;
    padding: 0.25rem 0.35rem;
    border-radius: 0.25rem;
    border: transparent;
    cursor: pointer;
  }
  .next-btn {
    right: -0.5rem;
  }
  .prev-btn {
    left: -0.5rem;
  }
  @media screen and (min-width: 768px) {
    .next-btn {
      right: -5rem;
    }
    .prev-btn {
      left: -5rem;
    }
    .btn {
      font-size: 1.3rem;
      padding: 0.35rem 0.5rem;
    }
    .quote-icon {
      font-size: 4rem;
    }
  }

  /* MORE CSS FOR JS */

  .slide-container {
    display: flex;
    position: relative;
    height: 450px;
    overflow: hidden;
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  .slide.active {
    opacity: 1;
  }
  // .slide.active {
  //   transform: translateX(0);
  // }
  // .slide.next {
  //   transform: translateX(100%);
  // }
  // .slide.last {
  //   transform: translateX(-100%);
  // }
}
