// Video
.mySass.VideoEffect {
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--clr-white);
    display: grid;
    justify-content: center;
    align-items: center;
    visibility: visible;
    z-index: 999;
    transition: var(--transition);
  }
  .hide-preloader {
    z-index: -999;
    visibility: hidden;
  }
  header {
    min-height: 100vh;
    position: relative;
    display: grid;
    place-items: center;
  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h1 {
    color: $white;
    z-index: 1;
  }

  // Switch button

  .switch-btn {
    position: absolute;
    bottom: 7%;
    left: 7%;
    width: 7rem;
    height: 2rem;
    display: flex;
    border-radius: $radius;
    align-items: center;
    justify-content: space-around;
    border: none;
    transition: $transition;
    z-index: 1;
  }
  .switch-btn span {
    display: inline-block;
    font-size: 0.85rem;
    cursor: pointer;
    text-transform: capitalize;
    color: $blue;
  }
  .switch {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background: $blue;
    border-radius: $radius;
    margin: 0;
    display: block;
    transition: $transition;
  }
  .slide .switch {
    left: 50%;
  }

  // Header after

  header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  // Button

  .btn {
    text-transform: uppercase;
    background: transparent;
    color: $black;
    padding: 0.375rem 0.75rem;
    letter-spacing: $spacing;
    display: inline-block;
    transition: $transition;
    font-size: 0.875rem;
    border: 2px solid $black;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: $radius;
  }
  .btn:hover {
    color: $white;
    background: $black;
  }

  .te-button {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    font-family: "Anonymous Pro", monospace;
    background-color: rgb(25, 25, 25);

    button {
      font-size: 120%;
      padding: 4px 6px;
      font-weight: bold;
      border: 1px solid white;
      border-radius: 5px;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
