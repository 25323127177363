.WeatherAPI {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(rgb(47, 150, 163), rgb(48, 62, 143));
  font-family: sans-serif;
  color: white;

  .location,
  .temperature {
    height: 30vh;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .temperature {
    flex-direction: column;
  }

  .degree-section {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .degree-section span {
    margin: 10px;
    font-size: 30px;
  }

  .degree-section h2 {
    font-size: 40px;
  }
}
