.contactForm {
    background-color: #161616;
}

.contactForm #contact-btn h1 {
    font-size: 2rem;
    color: #1e1e1e;
    font-family: calibri;
    margin: 10px;
    padding: 5px;
    letter-spacing: 4px;
    border-bottom: 2px solid #1e1e1e;
}

.contactForm #contact-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #f4f7ff;
    margin-bottom: 20px;
    border-top: solid 20px #161616;
    box-sizing: border-box;
    box-shadow: 2px 0px 20px rgba(0,0,0,0.4);
}

.contactForm #contact-btn span {
    cursor: pointer;
    color: #fff;
    font-size: 1rem;
    font-family: calibri;
    width: 150px;
    height: 40px;
    margin: 10px;
    background-color: #1e1e1e;
    box-shadow: 2px 5px 6px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactForm #contact-btn span:hover {
    background-color: #17d1ac;
    color: #000;
    transition: all ease 0.5s;
    font-weight: 700;
}

.contactForm #contact-form {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactForm #contact-form form {
    display: flex;
    width: 70%;
    height: auto;
    background-color: #1a1a1a;
    box-shadow: 2px 12px 20px rgba(0,0,0,0.2);
    border: 1px solid rgba(255,255,255,0.01);
}

.contactForm .contact-left {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactForm .social-icons span {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e3e3;
    margin: 35px 15px 10px 15px;
    border-radius: 50%;
}

.contactForm .social-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contactForm .social-icons i {
    color: #000;
}

.contactForm .social-icons span:hover{
    background-color: #000;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
    transition: all ease 0.5s;
}

.contactForm .social-icons span:hover i{
    color: #fff;
    transition: all ease 0.5s;
    padding-bottom: 2px;
    border-bottom: 2px solid #17d1ac;
}

.contactForm .contact-right {
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid rgba(255,255,255,0.08);
}

.contactForm .c-l-heading {
    font-family: calibri;
    color: #fff;
    font-size: 3rem;
    letter-spacing: 2px;
    font-weight: 400;
}

.contactForm .f-email,
.contactForm .f-name {
    display: flex;
    flex-direction: column;
    font-family: calibri;
}

.contactForm .f-email .font,
.contactForm .f-name .font {
    color: #bfbfbf;
    font-size: 22px;
}

.contactForm .f-email input,
.contactForm .f-name input {
    height: 30px;
    width: 250px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #929292;
    margin: 10px 0;
    color: #ebebeb;
    letter-spacing: 1px;
}

.contactForm .f-email input::placeholder,
.contactForm .f-name input::placeholder {
    opacity: 0.5;
    letter-spacing: 1px;
}

.contactForm .f-email input:focus,
.contactForm .f-name input:focus {
    border-bottom: 1px solid #17d1ac;
    transition: all ease 0.5s;
}

.contactForm .message .font {
    font-size: 18px;
    color: #4e4e4e;
    font-family: calibri;
}

.contactForm .message {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.contactForm .contact-right textarea {
    width: 100%;
    height: 320px;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: border-box;
    color: #ebebeb;
    font-size: 16px;
    letter-spacing: 1px;
}

.contactForm .contact-right  textarea::placeholder {
    color: #ebebeb;
    font-size: 18px;
    letter-spacing: 2px;
    font-family: calibri;
}

.contactForm .contact-right button {
    width: 100%;
    height: 50px;
    background-color: #1ed98b;
    font-weight: bold;
    outline: none;
    border: none;
    margin: 0;
    color: #1b1b1b;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
}

@media(max-width: 1190px) {
    .contactForm #contact-form form {
        width: 95%;
    }
}

@media(max-width: 970px) {
    .contactForm #contact-btn h1 {
        font-size: 1.4rem;
        text-align: center;
    }
    .contactForm #contact-form {
        padding: 20px;
        height: auto;
        box-sizing: border-box;
    }
    .contactForm #contact-form form {
        flex-direction: column;
        height: auto;
        padding: 20px;
        width: 90%;
    }
    .contactForm .contact-left,
    .contactForm .contact-right {
        width: 100%;
        border: none;
    }
    .contactForm .f-email,
    .contactForm .f-name {
        width: 100%;
    }
    .contactForm .f-email input,
    .contactForm .f-name input {
        width: 100%;
        border: none;
        background-color: #262626;
        padding: 10px;
        box-sizing: border-box;
        height: 40px;
    }
    .contactForm textarea {
        background-color: #262626;
        margin: 10px 0;
        padding: 10px;
        height: 200px;
        width: 100%;
    }
    .contactForm .message {
        margin: 0;
    }
    .contactForm #contact-form form h1 {
        margin: 5px 0;
    }
}