.mySass.TodoVanilla {
  padding: 5em 0;
  display: grid;
  place-items: center;

  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 35rem;
  }
  .section-center {
    background: $white;
    border-radius: $radius;
    box-shadow: $light-shadow;
    transition: $transition;
    padding: 2rem;
  }
  .section-center:hover {
    box-shadow: $dark-shadow;
  }
  .td-alert {
    margin-bottom: 1rem;
    height: 1.25rem;
    display: grid;
    align-items: center;
    text-align: center;
    font-size: 0.7rem;
    border-radius: 0.25rem;
    letter-spacing: $spacing;
    text-transform: capitalize;
  }
  .alert-danger {
    color: #721c24;
    background: #f8d7da;
  }
  .alert-success {
    color: #155724;
    background: #d4edda;
  }
  .grocery-form h3 {
    color: $dark-blue2;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .td-form-control {
    display: flex;
    justify-content: center;
  }
  #grocery {
    padding: 0.25rem;
    padding-left: 1rem;
    background: $background;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    border-color: transparent;
    font-size: 1rem;
    flex: 1 0 auto;
    color: $blue;
  }
  #grocery::placeholder {
    font-family: $ff-secondary;
    color: $blue;
  }
  .submit-btn {
    background: $light-blue2;
    border-color: transparent;
    flex: 0 0 5rem;
    display: grid;
    align-items: center;
    padding: 0.25rem;
    text-transform: capitalize;
    letter-spacing: 2px;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
    cursor: pointer;
    content: $blue;
    transition: $transition;
    font-size: 0.85rem;
  }
  .submit-btn:hover {
    background: $blue;
    color: $white;
  }

  .grocery-container {
    margin-top: 2rem;
    transition: $transition;
    visibility: hidden;
  }
  .show-container {
    visibility: visible;
  }
  .grocery-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    transition: $transition;
    padding: 0.25rem 1rem;
    border-radius: $radius;
    text-transform: capitalize;
  }
  .grocery-item:hover {
    color: $grey;
    background: $background;
  }
  .grocery-item:hover .title {
    color: $dark-blue3;
  }
  .title {
    margin-bottom: 0;
    color: $dark-blue3;
    letter-spacing: 2px;
    transition: $transition;
  }
  .edit-btn,
  .delete-btn {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    font-size: 0.7rem;
    margin: 0 0.15rem;
    transition: $transition;
  }
  .edit-btn {
    color: $salad;
  }
  .edit-btn:hover {
    color: $green;
  }
  .delete-btn {
    color: $red-light;
  }
  .delete-btn:hover {
    color: $red;
  }
  .clear-btn {
    text-transform: capitalize;
    width: 10rem;
    height: 1.5rem;
    display: grid;
    align-items: center;
    background: transparent;
    border-color: transparent;
    color: $red-light;
    margin: 0 auto;
    font-size: 0.85rem;
    letter-spacing: $spacing;
    cursor: pointer;
    transition: $transition;
    margin-top: 1.25rem;
  }
  .clear-btn:hover {
    color: $red;
  }
}
