.FlexGallery {
  font-family: "Poppins", sans-serif;
}

.FlexGallery .fg-container {
  width: 1000px;
  height: 400px;
  background: #ccc;
  display: flex;
  margin: 120px auto;
}

.FlexGallery .fg-col {
  height: 100%;
  flex: 1;
  color: #fff;
  box-sizing: border-box;
  padding: 25px;
  position: relative;
  transition: 0.6s;
  /*  */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FlexGallery .fg-col:nth-child(1) {
  background: #283491;
}

.FlexGallery .fg-col:nth-child(2) {
  background: #f18684;
}

.FlexGallery .fg-col:nth-child(3) {
  background: #6ee2f1;
}

.FlexGallery h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.FlexGallery .caption {
  max-width: 230px;
  font-size: 12px;
  margin: 28px 0;
  display: none;
}

.FlexGallery .caption button {
  border: 1px solid #fff;
  background: transparent;
  outline: none;
  padding: 8px 18px;
  color: #fff;
  border-radius: 30px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px;
}

.FlexGallery .fg-col img {
  width: 200px;
  /* position: absolute;
    right: 0;
    bottom: 0; */
  position: relative;
  transition: 1.2s ease-in-out;
}

.FlexGallery .fg-col:hover {
  flex-grow: 2.5;
  /*  */
  display: initial;
}

.FlexGallery .fg-col:hover .caption {
  display: block;
}

.FlexGallery .fg-col:hover h2 {
  text-align: left;
}

.FlexGallery .fg-col:hover img {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: 0.6s ease-in-out;
}
